@import '../../scss/mixins';

header {
  z-index: 9999;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include lg {
    flex-direction: column;
    align-items: center;
  }

  &-left {
    display: flex;
    flex-direction: row;
    gap: 8px;

    @include lg {
      width: 100%;
      justify-content: space-between;
      gap: 4px;
    }
  }

  &-menu {
    position: relative;
    display: inline-block;

    &-btn {
      font-weight: bold;

      img {
        margin-right: 4px;
      }
    }

    &-expanded {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      img {
        margin: 0;
      }
    }

    ul {
      position: absolute;
      left: 0;
      top: 100%;

      @include sm {
        width: 100%;
        top: auto;
        bottom: 0;
        left: 0;
      }
    }
  }

  &-menu-list {
    width: 100% !important;
  }

  &-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: nowrap;
    white-space: nowrap;

    .selected {
      font-weight: bold;
    }

    @include lg {
      width: auto;
      justify-content: flex-end;
      margin-left: auto;
      gap: 0;
    }

    @include sm {
      font-size: 12px;
    }
  }

  &-search {
    display: flex;
    width: 50%;
    margin-right: 4px;
    gap: 8px;
    height: 40px;

    @include lg {
      width: 100%;
      margin-right: 0;
    }
  }
}
