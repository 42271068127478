@import '../../scss/mixins';

.user-management {
  background-color: teal;

  .user-management-window {
    position: relative;
    margin-top: 60px;
    width: 100%;

    @include lg {
      margin-top: 40px;
    }

    @include sm {
      margin-top: 20px;
    }

    .user-management-actions {
      display: flex;
      align-items: center;
      gap: 20px;

      @include lg {
        justify-content: flex-start;
      }

      @include sm {
        gap: 10px;
      }

      > * {
        margin-right: 0;
      }
    }

    .remove-user-text {
      color: red;
    }

    .user-management-table-container {
      @include xl {
        overflow-x: auto;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        white-space: nowrap;
      }
    }
  }
}
