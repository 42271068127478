.login {
  text-align: center;
  padding: 100px 0;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    padding: 5px;
    color: #26a69a;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
  }

  .errorBlock {
    background: #f44336;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
  }
}
