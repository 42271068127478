@import '../../scss/mixins';

.error-modal {
  position: absolute !important;
  z-index: 9999;
  min-width: 25%;
  max-width: 35%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgb(253, 246, 227);
  box-shadow: rgba(0, 0, 0, 0.35) 4px 4px 10px 0px, rgb(253, 246, 227) 1px 1px 0px 1px inset,
    rgb(253, 246, 227) -1px -1px 0px 1px inset;

  @include xl {
    min-width: 35%;
    max-width: 50%;
  }

  @include lg {
    min-width: 50%;
    max-width: 70%;
  }

  @include sm {
    width: 100%;
    min-width: unset;
    max-width: unset;
    left: 0;
    transform: translate(0, -50%);
  }

  &-header {
    background: linear-gradient(to right, rgb(142, 1, 1), rgb(230, 79, 111)) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > button {
      font-weight: bold;
    }
  }

  &-body {
    overflow-wrap: anywhere;
    white-space: normal;
    background: rgb(238, 232, 213);
  }

  &-footer {
    padding: 0 2px;
    overflow-wrap: anywhere;
    white-space: normal;
    border: 2px solid rgb(253, 246, 227) !important;
    background: rgb(238, 232, 213) !important;
    color: rgb(101, 123, 131) !important;
  }
}
